import React from "react";
import theme from "theme";
import { Theme, Text, Section, Box, Image, LinkBox } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Nos services | Nos services juridiques à FRAÏOLI
			</title>
			<meta name={"description"} content={"Au-delà des solutions juridiques - Une clarté adaptée à vos besoins uniques\n"} />
			<meta property={"og:title"} content={"Nos services | Nos services juridiques à FRAÏOLI"} />
			<meta property={"og:description"} content={"Au-delà des solutions juridiques - Une clarté adaptée à vos besoins uniques\n"} />
			<meta property={"og:image"} content={"https://versaillesvoyage.com/media/8.jpg"} />
			<link rel={"shortcut icon"} href={"https://versaillesvoyage.com/media/ch--new-icon.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://versaillesvoyage.com/media/ch--new-icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://versaillesvoyage.com/media/ch--new-icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://versaillesvoyage.com/media/ch--new-icon.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://versaillesvoyage.com/media/ch--new-icon.png"} />
			<meta name={"msapplication-TileImage"} content={"https://versaillesvoyage.com/media/ch--new-icon.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text as="h2" font="--headline1" md-font="--headline2" margin="20px 0 0 0">
				Nos services
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
				Clear Counsel vous propose une exploration nuancée des solutions juridiques. Nos services dépassent les frontières traditionnelles, offrant une clarté et une résolution sur mesure pour répondre à vos besoins uniques. Qu'il s'agisse de questions juridiques personnelles, de services de conseil aux entreprises, de révision de contrats, de résolution de litiges ou de consultation en matière de droit du travail, chaque offre est élaborée avec précision. Rejoignez-nous pour naviguer dans le paysage juridique en vous concentrant sur vos besoins spécifiques.
			</Text>
		</Section>
		<Section padding="20px 0 80px 0" lg-padding="60px 0 60px 0" md-padding="40px 0 40px 0" sm-padding="30px 0 30px 0">
			<Box margin="0px 0px 50px 0px" display="flex" flex-direction="column" align-items="flex-start">
				<Text
					font="--headline2"
					color="--dark"
					text-align="center"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					margin="16px 0px 50px 0px"
				>
					Points forts des services
				</Text>
			</Box>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" sm-width="100%" lg-padding="0px 0px 0px 0px">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
							Questions juridiques personnelles :
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
							Des solutions juridiques sur mesure pour les défis personnels, garantissant clarté et conseils.
						</Text>
					</Box>
				</Box>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://versaillesvoyage.com/media/3.jpg"
						border-radius="24px"
						max-width="100%"
						width="100%"
						object-fit="none"
						max-height="400px"
						sm-max-height="213px"
					/>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://versaillesvoyage.com/media/4.jpg"
						border-radius="24px"
						max-width="100%"
						width="100%"
						object-fit="none"
						max-height="400px"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" sm-width="100%" lg-padding="0px 0px 0px 0px">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
							Services de conseil aux entreprises :
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
							Conseil juridique stratégique pour les entreprises, afin de les aider à surmonter les difficultés en vue de leur croissance.
						</Text>
					</Box>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" sm-width="100%" lg-padding="0px 0px 0px 0px">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
							Examen et rédaction de contrats :
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
							Examen approfondi et rédaction de contrats, garantissant la conformité et la protection juridiques.
						</Text>
					</Box>
				</Box>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://versaillesvoyage.com/media/5.jpg"
						border-radius="24px"
						max-width="100%"
						width="100%"
						object-fit="none"
						max-height="400px"
						sm-max-height="213px"
					/>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://versaillesvoyage.com/media/6.jpg"
						border-radius="24px"
						max-width="100%"
						width="100%"
						object-fit="none"
						max-height="400px"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" sm-width="100%" lg-padding="0px 0px 0px 0px">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
							Résolution des litiges :
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
							Assistance d'experts dans le cadre de litiges juridiques, en mettant l'accent sur une résolution rapide et efficace.
						</Text>
					</Box>
				</Box>
			</LinkBox>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					md-text-align="left"
				>
					Contact
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					Pour une compréhension plus détaillée de nos services ou pour discuter de vos besoins juridiques spécifiques, nous vous invitons à contacter FRAÏOLI. Notre équipe dévouée est prête à vous apporter la clarté et les conseils que vous recherchez.
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://versaillesvoyage.com/media/7.jpg"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});